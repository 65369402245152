import React, {Component, Suspense} from 'react';
import "../static/css/common.less";
import {BrowserRouter, Route, Navigate, Routes} from 'react-router-dom';
import Loading from "../common/component/loading";
const GameResult = React.lazy(()=>import("./result"));
const GameTrend = React.lazy(()=>import("./trend"));
const GamePrediction = React.lazy(()=>import("./predict"));
const GameStatistics = React.lazy(()=>import("./statistics"));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }
    render() {
        return (
            <Suspense fallback={<Loading />}>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="/:game/result" element={<GameResult />} />
                        <Route exact path="/:game/trend" element={<GameTrend />} />
                        <Route exact path="/:game/predict" element={<GamePrediction />} />
                        <Route exact path="/:game/statistics" element={<GameStatistics />} />
                        <Route path="*" element={<Navigate to="/can/result" replace />} />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        );
    }
}

export default App;

